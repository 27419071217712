// In un file come apollo.js o utilizzando un provider
import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_ENDPOINT_SERVER, // Sostituisci con l'URL del tuo server GraphQL
  cache: new InMemoryCache(),
});

// export default client;



export const getApolloClient = () => {
  return new ApolloClient({
    uri: process.env.REACT_APP_ENDPOINT_SERVER, // Sostituisci con l'URL del tuo server GraphQL
    cache: new InMemoryCache(),
  });
};



// src/utils/apollo-client.js
// import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

// // Crea una funzione per loggare le query
// const logLink = new HttpLink({
//   uri: process.env.REACT_APP_ENDPOINT_SERVER,
//   fetch: (uri, options) => {
//     // console.log('Invio della query a:', uri);
//     // console.log('Opzioni:', options);
//     return fetch(uri, options);
//   },
// });

// const client = new ApolloClient({
//   link: logLink,
//   cache: new InMemoryCache(),
// });

// export default client;